<template>
<div>
    <section>
        <AdvanceTable ref="advanceTable" :url="apis.erpBankStatementItems" :height="window.height - 310"  tableSize="sm" :columns="columns" v-loading="isLoading" @edit="editRow">
          <template #button>
            <b-button variant="secondary" class="mr-50" @click="openModalForUpload">
              <feather-icon icon="UploadIcon"/> Upload Bank Statement
            </b-button>
          </template>
          <template #refresh_button>
            <b-button variant="secondary" @click="loadList()" class="mr-1 btn-icon">
              <feather-icon icon="RefreshCwIcon" />
            </b-button>
          </template>
          <template #cell(accountNumber)="row">
            {{ row.item.accountNumber }}
            <div v-if="typeof bankAccountMap[row.item.accountNumber] != 'undefined'" class="text-muted">
              {{ bankAccountMap[row.item.accountNumber].accountCurrency }} Account
            </div>
          </template>
          <template #cell(transactionDate)="row">
            {{ row.item.transactionDate.substring(0, 10) }}
          </template>
          <template #cell(transactionAmount)="row">
            {{ row.item.transactionAmount }} {{ row.item.transactionCurrency }}
          </template>
          <template #cell(settlementAmount)="row">
            {{ row.item.settlementAmount }} {{ row.item.transactionCurrency }}
          </template>
          <template #cell(status)="row">
            <el-tag size="mini" type="success" v-if="row.item.status === 'FULLY_SETTLED'">{{ $t('Fully Settled') }}</el-tag>
            <el-tag size="mini" type="primary" v-else-if="row.item.status === 'PARTIALLY_SETTLED'">{{ $t('Partially Settled') }}</el-tag>
            <el-tag size="mini" type="info" v-else-if="row.item.status === 'IGNORED'">{{ $t('Not Relevant') }}</el-tag>
            <el-tag size="mini" type="warning" v-else-if="row.item.status === 'PENDING'">{{ $t('Pending') }}</el-tag>
            <el-tag size="mini" type="primary" v-else>{{ row.item.status }}</el-tag>
          </template>
          <template #cell(description)="row">
            <div v-if="row.item.description.length > 100">
              {{ row.item.description.substring(0, 100) }}...
            </div>
            <div v-else>
              {{ row.item.description }}
            </div>
          </template>
          <template #cell(actions)="row">
            <div>
              <el-link icon="el-icon-edit" @click="editRow(row.item)" class="d-none">{{$t('Edit')}}</el-link>
              <el-link icon="el-icon-delete" @click="deleteRow(row.item)" class="ml-1 d-none">{{$t('Delete')}}</el-link>

              <el-link icon="el-icon-success" @click="settleRow(row.item)" v-if="row.item.status === 'PARTIALLY_SETTLED' || row.item.status === 'PENDING'">{{$t('Settle')}}</el-link>
              <el-link icon="el-icon-error" @click="ignoreRow(row.item)" v-if="row.item.status === 'PENDING'">{{$t('Not Relevant')}}</el-link>
            </div>
          </template>
        </AdvanceTable>
    </section>

    <el-dialog
      class="compact"
      :title="$t(title)"
      :visible.sync="isShow"
    >
      <b-row>
        <b-col lg="3">
          <b-form-group :label="$t('Account Number')">
            <el-input v-model="row.accountNumber" readonly="readonly"/>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group :label="$t('Transaction Date')">
            <el-input :value="row.transactionDate ? row.transactionDate.substring(0, 10) : ''" type="input" readonly="readonly"/>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group :label="$t('Transaction Amount')">
            <el-input v-model="row.transactionAmount" readonly="readonly"/>
          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group :label="$t('Transaction Currency')">
            <el-input v-model="row.transactionCurrency" readonly="readonly"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12">
          <b-form-group :label="$t('Description')">
            <el-input type="textarea" v-model="row.description" :autosize="{ minRows: 5, maxRows: 10}" readonly="readonly"/>
          </b-form-group>
        </b-col>
      </b-row>

      <hr/>

      <b-row>
        <b-col lg="12">
          <h5>Settlement History</h5>
          <table class="table mt-2" style="width: 100%">
            <thead>
              <tr>
                <th>Time</th>
                <th>Invoice Number</th>
                <th>Type</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="settlement in row.settlements">
                <td>{{ settlement.addTime }}</td>
                <td>{{ settlement.invoiceNumber }}</td>
                <td>{{ settlement.type }}</td>
                <td>{{ settlement.amount }} {{ settlement.currency }}</td>
              </tr>
            </tbody>
          </table>`
        </b-col>
      </b-row>
      <div>
        <hr/>
        <h5>Actions</h5>
        <div class="w-100 d-flex">
          <el-button type="success" icon="el-icon-success" class="mr-2" @click="settleRow(row)" v-if="row.status === 'PARTIALLY_SETTLED' || row.status === 'PENDING'">{{$t('Settle')}}</el-button>
          <el-button type="warning" icon="el-icon-error" class="mr-2" @click="ignoreRow(row)" v-if="row.status === 'PENDING'">{{$t('Not Relevant')}}</el-button>
          <el-button type="info" icon="el-icon-search" class="mr-2" @click="showAudits(row)">{{$t('Audits')}}</el-button>
        </div>
      </div>
    </el-dialog>

  <el-dialog
    class="compact"
    :title="$t('Settlement')"
    :visible.sync="isShowSettle"
  >
    <b-row>
      <b-col lg="12">
        <b-form-group :label="$t('Invoice Number')">
          <el-select v-model="settlement.invoiceNumber" placeholder="Start typing invoice number" size="mini"
                     filterable
                     remote
                     :remote-method="remoteMethod"
                     :loading="isInvoiceLoading"
                     style="width: 100%">
            <el-option
              v-for="item in invoiceOptions"
              :key="item.invoiceNumber"
              :label="item.invoiceNumber"
              :value="item.invoiceNumber">
              {{ item.invoiceNumber }} - {{ item.accountCode }} - {{ item.totalAmount }} {{ item.totalAmountCurrency }}
              <InvoiceStatusTag :item="item" class="float-right"/>
            </el-option>
          </el-select>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="6">
        <b-form-group :label="$t('Transaction Amount')">
          <el-input v-model="row.transactionAmount" class="disableable" size="mini" readonly="readonly"/>
        </b-form-group>
      </b-col>
      <b-col lg="6" class="mt-2">
        <el-radio v-model="settlement.type" label="FULL" @change="changeSettleType()">Full Settlement</el-radio>
        <el-radio v-model="settlement.type" label="PARTIAL" @change="changeSettleType()">Partial Settlement</el-radio>
      </b-col>
    </b-row>

    <b-row class="mt-1">
      <b-col lg="3">
        <b-form-group :label="$t('Unsettled Amount')">
          <el-input v-model="settlement.settledAmount" class="disableable" size="mini" readonly="readonly"/>
        </b-form-group>
      </b-col>

      <b-col lg="1">
        <span style="font-size: 48px; line-height: 80px;">-</span>
      </b-col>

      <b-col lg="3">
        <b-form-group :label="$t('Amount to Settle')">
          <el-input v-model="settlement.amount" class="disableable" size="mini" @change="changeSettleAmount()" :readonly="settlement.type === 'FULL'"/>
        </b-form-group>
      </b-col>

      <b-col lg="1">
        <span style="font-size: 48px; line-height: 80px;">=</span>
      </b-col>

      <b-col lg="3">
        <b-form-group :label="$t('Remaining Amount')">
          <el-input v-model="settlement.remainingAmount" class="disableable" size="mini" readonly="readonly"/>
        </b-form-group>
      </b-col>
    </b-row>
    <div>
      <div class="w-100 d-flex justify-content-end">
        <b-button variant="primary" class="btn" @click="saveSettlement()">
          {{ $t('Submit') }}
        </b-button>
      </div>
    </div>
  </el-dialog>

  <UploadModal ref="uploadView">
    <template v-slot:tip="scope">
      Only accepts xlsx files with size less than {{ $refs.uploadView ? $refs.uploadView.getMaxSizeForDisplay() : '' }}<br/>
      <a href="https://prod-kec-dashboard.s3.ap-east-1.amazonaws.com/public/Bank-Statement-Upload-Template.xlsx" target="_blank" @click.stop>Download Template</a>
    </template>
  </UploadModal>

  <ExcelResultModal ref="excelResult"/>
  <AuditTrailModal ref="auditTrail"/>
</div>
</template>

<script>
import { getAccessToken, getErpProfile } from "@/utils/account-localstorage";
import AdvanceTable from "../components/advanceTable/advanceTable.vue";
import apis from "@/configs/apis";
import { BButton, BModal } from "bootstrap-vue";
import UploadModal from "@/views/erp/components/UploadModal.vue";
import Prompt from "@/views/erp/mixins/Prompt";
import ExcelResultModal from "@/views/erp/components/ExcelResultModal.vue";
import AuditTrailModal from "@/views/erp/components/AuditTrailModal.vue";
import axios from "axios";
import InvoiceStatusTag from "@/views/erp/components/InvoiceStatusTag.vue";
import _ from "lodash";

export default {
  mixins: [Prompt],
  components: {
    InvoiceStatusTag,
    AuditTrailModal,
    ExcelResultModal,
    UploadModal,
    BButton,
    BModal,
    AdvanceTable,
  },
  data() {
    return {
      window: {
        width: 0,
        height: 0
      },
      columns: [
        { key: "accountNumber", modelName: "accountNumber", label: "Account Number", width: "120", filtertype: "input", sortable: true },
        { key: "transactionDate", modelName: "transactionDate", label: "Transaction Date", width: "120", filtertype: "input", sortable: true },
        { key: "description", modelName: "description", label: "Description", width: "400", filtertype: "input", sortable: true },
        { key: "transactionAmount", modelName: "transactionAmount", label: "Transaction Amount", width: "150", filtertype: "input", sortable: true },
        { key: "settlementAmount", modelName: "settlementAmount", label: "Settled Amount", width: "150", filtertype: "input", sortable: true },
        { key: "status", modelName: "status", label: "Status", width: "120", filtertype: "select", "options": {'FULLY_SETTLED': 'Fully Settled', 'PARTIALLY_SETTLED': 'Partially Settled', 'IGNORED': 'Not Relevant', 'PENDING': 'Pending'}, sortable: true },
        { key: "uploadBy", modelName: "uploadBy", label: "Upload By", width: "100", filtertype: "input", sortable: true },
        /* { key: "actions", modelName: "actions", label: "Actions", sortable: false, width: "200" }, */
      ],
      profileSelected: null,
      profileList: [],
      itemList: [],

      action: null,
      isShow: false,
      isShowProfile: false,
      isShowSettle: false,
      title: '',
      row: {},
      settlement: {},
      profile: {},
      isLoading: false,
      isInvoiceLoading: false,
      invoiceOptions: [],
      bankAccounts: [],

      form: {
        currencyProfileId: null,
        clientFilename: null,
        serverFilename: null,
      },
    };
  },
  computed: {
    apis() {
      return apis
    },
    bankAccountMap: function() {
      let map = {};

      this.bankAccounts.forEach(row => {
        map[row.accountNumber] = row;
      })

      return map;
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.loadBankAccounts();
  },
  methods: {
    getAccessToken,
    getErpProfile,
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    openModalForView(item) {
      this.$alert(item.remark, 'Remark: ' + item.code);
    },
    openModalForCreate() {
      Object.keys(this.row).forEach(key => (this.row[key] = ''));
      this.isShow = true
      this.action = 'CREATE'
      this.title = 'Add Bank Statement Item'

      this.row = {currencyProfileId: this.profileSelected ? this.profileSelected.id : null};
    },
    openModalForUpload() {
      this.$refs.uploadView.showUpload('Upload Bank Statement', 'BANK_STATEMENT', {maxSize: 10485760, allowedExtension: 'xlsx'}, (form) => {
        this.isLoading = true;

        axios.post(
          apis.erpProcessBankStatementItems,
          form
        ).then((response) => {
          this.loadList();
          this.$refs.uploadView.hideUpload();
          this.$refs.excelResult.showResult(response.data.data);
        }).catch(e => {
          console.log('Catch', e, e.stack);
          this.promptError(e);
        }).finally(() => {
          this.isLoading = false;
        });
      });
    },
    editRow(item) {
      this.row = _.clone(item);
      this.isShow = true
      this.action = 'EDIT'
      this.title = 'Bank Statement Item'
    },

    async loadBankAccounts() {
      const response = await axios.get(apis.erpBankAccounts);
      this.bankAccounts = response.data.data.data;
    },

    async loadList() {
      await this.$refs.advanceTable.loadList();
    },
    async save() {
      try {
        const response = await axios.put(
          apis.erpBankStatementItems + "/" + this.row.id,
          {
            "description": this.row.description,
          }
        );
        await this.loadList();
        this.isShow = false;

        this.promptInfo("Update Bank Statement Item Success");
      } catch (e) {
        this.promptError(e);
      }
    },
    deleteRow(row) {
      this.row = row;
      this.$confirm('Are you sure to delete row with ' + row.transactionAmount + ' ' + row.transactionCurrency +  '?', 'Confirm Delete')
        .then(() => {
          this.isLoading = true;
          return axios.delete(apis.erpBankStatementItems + "/" + row.id)
        })
        .catch(e => {
          this.promptError(e);
        })
        .finally(() => {
          this.isLoading = false;
          this.promptInfo('Row deleted.')
          this.loadList()
        });
    },
    settleRow(row) {
      this.row = row;

      this.settlement = {
        action: 'SETTLE',
        invoice: '',
        type: 'FULL',
        fullAmount: this.row.transactionAmount,
        settledAmount: this.row.settlementAmount,
        unsettledAmount: this.row.transactionAmount - this.row.settlementAmount,
        amount: this.row.transactionAmount - this.row.settlementAmount,
        remainingAmount: 0.00,
      };
      this.isShowSettle = true;
    },
    ignoreRow(row) {
      this.row = row;
      this.$confirm('Are you sure to mark irrelevant for row with ' + row.transactionAmount + ' ' + row.transactionCurrency +  '?', 'Confirm Mark Irrelevant')
        .then(() => {
          this.isLoading = true;
          axios.post(apis.erpBankStatementItems + "/" + row.id + '/settle',  {action: 'NOT_RELEVANT'})
            .then(response => {
              this.promptInfo('Row marked as Irrelevant.')
            }).catch(e => {
              this.promptError(e);
            }).finally(() => {
              this.isLoading = false;
              this.isShow = false;
              this.loadList()
            });
        })
        .catch(() => {});
    },
    saveSettlement() {
      this.isLoading = true;
      axios.post(apis.erpBankStatementItems + "/" + this.row.id + '/settle', this.settlement)
        .then(response => {
          this.promptInfo('Settlement saved.')
        }).catch(e => {
          this.promptError(e);
        }).finally(() => {
          this.isLoading = false;
          this.isShow = false;
          this.loadList();
          this.isShowSettle = false;
      });
    },
    changeSettleType() {
      if (this.settlement.type === 'FULL') {
        this.settlement.amount = this.settlement.unsettledAmount;
      }
    },
    changeSettleAmount() {
      if (this.settlement.amount > this.settlement.unsettledAmount) {
        this.settlement.amount = this.settlement.unsettledAmount;
      }

      this.settlement.remainingAmount = this.settlement.unsettledAmount - this.settlement.amount;
    },
    upload() {
      this.$refs.upload.submit();
    },
    remoteMethod(query) {
      if (query !== '') {
        this.isInvoiceLoading = true;

        axios.get(apis.erpInvoices, {params: {invoiceNumber: query}}).then(response => {
          this.invoiceOptions = response.data.data.data;
        }).finally(() => {
          this.isInvoiceLoading = false;
        })

      } else {
        this.options = [];
      }
    },

    showAudits(entity) {
      this.$refs.auditTrail.showMultiple([
        {className: 'com.kerrylogistics.dashboard.entities.erp.BankStatementItem', name: 'Bank Statement Item', objectId: entity.id},
        {className: 'com.kerrylogistics.dashboard.entities.erp.BankStatementItemSettlement', name: 'Bank Statement Item Settlement', objectId: entity.settlements.map(o => o.id)}
      ]);
    }
  }
};
</script>
